import "bootstrap/dist/css/bootstrap.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { useState } from "react";
import Zoom from "react-reveal/Zoom";

function ClienteleMobile(props) {
    const [data, setData] = useState([
        {
            id: "1",
            image: "/clientele/clientele-1.png"
        },
        {
            id: "2",
            image: "/clientele/clientele-2.png"
        },
        {
            id: "3",
            image: "/clientele/clientele-3.png"
        },
        {
            id: "4",

            image: "/clientele/clientele-4.png"
        },
        {
            id: "5",

            image: "/clientele/clientele-6.png"
        }
    ]);
    return (
        <>
            <Zoom right>
                <div className="clientele">
                    <h2>Our Clientele</h2>
                    <div className="clientele-container">
                        <Swiper
                            className="swiper-container"
                            modules={[Autoplay, Pagination]}
                            pagination={{ clickable: true }}
                            autoplay={{ delay: 5500 }}
                            speed={1000}
                            spaceBetween={10}
                            slidesPerView={4}
                        >
                            {data.map((item, index) => (
                                <SwiperSlide key={item.id}>
                                    <img alt="clientele" className={item.id === 5 ? "client-specific-image" : "clientele-image"} src={item.image} />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </Zoom>
        </>
    );
}

export default ClienteleMobile;
