import { Route, Switch } from "react-router-dom";
import Home from "../Home";
import Layout from "../Layout";
import Products from "./../components/Products/Products";
import Gallery from "./../components/Gallery/Gallery";
import Seminars from "../components/Workshops/Seminars";

const Routes = () => (
    <>
        <Layout>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/products" component={Products} />
                <Route exact path="/gallery" component={Gallery} />
                <Route exact path="/seminars" component={Seminars} />
            </Switch>
        </Layout>
    </>
);

export default Routes;
