import React, { useEffect, useState } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { Link } from "react-router-dom";
import Offcanvas from "react-bootstrap/Offcanvas";
import { useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}
const Layout = ({ children }) => {
    const [height, width] = useWindowSize();
    let currentLoc = useLocation();

    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const handleClose = () => {
        setMenuOpen(false);
    };

    return (
        <div className="App w100">
            <Navbar
                collapseOnSelect
                className={
                    // navbar ? "custom-navbar active" :
                    "custom-navbar"
                }
                // sticky="top"
                variant={currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? "light" : "dark"}
                expand="lg"
            >
                <Container className="nav-container-style">
                    <Navbar.Brand href="/">
                        {currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? (
                            <img src="/icons/logo.png" alt="logo" className="nav-logo" />
                        ) : (
                            <img src="/icons/logo-white.png" alt="logo" className="nav-logo" />
                        )}
                    </Navbar.Brand>

                    <div>
                        {width < 768 ? (
                            <HashLink smooth style={{ width: 50, height: 50, marginLeft: "1rem", padding: "1rem" }} to="/#contact">
                                <img className="phone-icon" src="/icons/phone.png" alt="phone" />
                            </HashLink>
                        ) : null}
                        <Navbar.Toggle onClick={toggleMenu} aria-controls="basic-navbar-nav" />
                    </div>
                    <Navbar.Offcanvas
                        show={menuOpen}
                        style={{ backgroundColor: "#32B368", zIndex: 9999 }}
                        onHide={handleClose}
                        id={`basic-navbar-nav`}
                        aria-labelledby={`basic-navbar-nav`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`basic-navbar-nav`}>The Better Life Projects</Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body className={width > 769 ? "w100 justify-content-around" : "d-flex justify-content-between flex-column"}>
                            <div className={width > 769 ? "w100 d-flex justify-content-around" : "w100 "}>
                                <Nav className="align-items-center justify-content-center">
                                    {width > 768 ? (
                                        <HashLink className="nav-content nav-link hamburger-nav" to="/#about" onClick={width < 768 ? toggleMenu : null}>
                                            ABOUT
                                        </HashLink>
                                    ) : (
                                        <HashLink className="nav-content nav-link hamburger-nav" to="/" onClick={width < 768 ? toggleMenu : null}>
                                            Home
                                        </HashLink>
                                    )}

                                    {/* <Nav.Link style={{ fontSize: 17, fontWeight: 600 }} className="nav-content" href="#about">
                                        <Link
                                            className="nav-link "
                                            to="/"
                                            onClick={width < 768 ? toggleMenu : null}
                                            style={currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? { textDecoration: "none", color: "black" } : { textDecoration: "none" }}
                                        >
                                            ABOUT
                                        </Link>
                                    </Nav.Link> */}
                                    <Nav.Link className="nav-content" style={{ width: "100%" }}>
                                        <Link
                                            className="nav-link hamburger-nav"
                                            to="/products"
                                            onClick={width < 768 ? toggleMenu : null}
                                            style={currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? { textDecoration: "none", color: "black" } : { textDecoration: "none" }}
                                        >
                                            PRODUCTS
                                        </Link>
                                    </Nav.Link>
                                    <Nav.Link style={{ width: "100%" }} className="nav-content">
                                        <Link
                                            className="nav-link hamburger-nav"
                                            to="/gallery"
                                            onClick={width < 768 ? toggleMenu : null}
                                            style={currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? { textDecoration: "none", color: "black" } : { textDecoration: "none" }}
                                        >
                                            GALLERY
                                        </Link>
                                    </Nav.Link>
                                    <Nav.Link style={{ width: "100%" }} className="nav-content">
                                        <Link
                                            className="nav-link hamburger-nav"
                                            to="/seminars"
                                            onClick={width < 768 ? toggleMenu : null}
                                            style={currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? { textDecoration: "none", color: "black" } : { textDecoration: "none" }}
                                        >
                                            WORKSHOPS
                                        </Link>
                                    </Nav.Link>
                                </Nav>
                                <Nav>
                                    {width > 768 ? (
                                        <Button className="d-flex align-items-center contact-button justify-content-center ">
                                            {currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? (
                                                <img className="phone-icon" src="/icons/phone-black.png" alt="phone" />
                                            ) : (
                                                <img className="phone-icon" src="/icons/phone.png" alt="phone" />
                                            )}
                                            <HashLink
                                                className={
                                                    currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars" ? "contact-button-specific" : "contact-button-style nav-content nav-link "
                                                }
                                                to="/#contact"
                                            >
                                                CONTACT
                                            </HashLink>
                                            {/* <Nav.Link
                                                href="#contact"
                                                style={
                                                    currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars"
                                                        ? { fontSize: 17, fontWeight: 600, color: "black" }
                                                        : { fontSize: 17, fontWeight: 600 }
                                                }
                                            >
                                                CONTACT
                                            </Nav.Link> */}
                                        </Button>
                                    ) : null}
                                    {/* (
                                         <HashLink
                                             smooth
                                            className="nav-link hamburger-nav"
                                             style={{ fontSize: 17, fontWeight: 600, color: "black", textDecoration: "none" }}
                                             onClick={toggleMenu}
                                             to="/#contact"
                                         >
                                             CONTACT
                                         </HashLink>
                                         <Nav.Link
                                             href="#contact"
                                             onClick={toggleMenu}
                                             style={
                                                 currentLoc.pathname === "/gallery" || currentLoc.pathname === "/seminars"
                                                     ? { fontSize: 17, fontWeight: 600, color: "black" }
                                                     : { fontSize: 17, fontWeight: 600 }
                                             }
                                         >
                                             CONTACT
                                         </Nav.Link>
                                     )} */}
                                </Nav>
                            </div>
                            {width < 768 ? <h2 className="dont-let-text">Don't let your story end...</h2> : null}
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Container>
            </Navbar>

            <div>{children}</div>
        </div>
    );
};

export default Layout;
