import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";
import Tada from "react-reveal/Tada";

function PurchaseForm(props) {
    const [state, handleSubmit] = useForm("xpzeybvl");

    return (
        <form style={{ padding: "1rem" }} onSubmit={handleSubmit}>
            <Row className="w-100" style={{ textAlign: "start", margin: 0 }}>
                <Col>
                    {!state.succeeded ? (
                        <>
                            <h3 className="feedback-heading">Please Fill In The Form</h3>
                            <p style={{ fontSize: 13, color: "white", marginTop: 30 }}>Fill the form and we will get back to you, swiftly.</p>
                            <Tada>
                                <p style={{ fontSize: 20, color: "white", marginTop: 30 }}>Let's talk...</p>
                            </Tada>
                            <div className="mb-3 formStart">
                                <label htmlFor="name" className="form-label">
                                    Name
                                </label>

                                <input required id="name" className="form-control transparentInput" name="name" label="Name" placeholder="Name" />
                                <ValidationError prefix="Name" field="name" errors={state.errors} />
                            </div>
                            <div className="mb-3 formStart">
                                <label htmlFor="email" className="form-label">
                                    Email
                                </label>

                                <input required id="email" type="email" className="form-control transparentInput" name="email" label="Email" placeholder="Email" />
                                <ValidationError prefix="Email" field="email" errors={state.errors} />
                            </div>
                            <div className="mb-3 formStart">
                                <label htmlFor="phone" className="form-label">
                                    Phone
                                </label>

                                <input
                                    type="tel"
                                    maxLength={10}
                                    title="Ten digits"
                                    pattern="[1-9]{1}[0-9]{9}"
                                    required
                                    id="phone"
                                    className="form-control transparentInput"
                                    name="phone"
                                    label="Phone"
                                    placeholder="Phone"
                                />
                                <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                            </div>
                            <button className="btn writeButton center" type="submit" disabled={state.submitting}>
                                SUBMIT
                            </button>
                        </>
                    ) : (
                        <h3 className="thank-you-text">Thank You, We'll Get Back To You</h3>
                    )}
                </Col>
            </Row>
        </form>
    );
}

export default PurchaseForm;
// class PurchaseForm extends Form {
//     state = {
//         data: {
//             name: "",
//             email: "",
//             phone: ""
//         },
//         errors: {
//             name: "",
//             email: "",
//             phone: ""
//         },
//         isProcessing: false
//     };

//     schema = {
//         phone: Joi.string()
//             .length(10)
//             .regex(/^[0-9]+$/)
//             .required()
//             .label("Phone Number"),
//         name: Joi.string().max(75).required().label("Name"),
//         email: Joi.string().email().required().label("Email")
//     };

//     doSubmit = async () => {
//         console.log(this.state.data);
//         // this.setState({ isProcessing: true, apiError: "" });
//         // try {
//         //   const result = await auth.generateOTP({
//         //     phone: this.state.data.phone,
//         //   });
//         //   localStorage.setItem("phone", this.state.data.phone);
//         //   this.setState({ isProcessing: false });
//         //   if (result.status === 200) this.props.history.push("/enter-otp"); //doing redirect here.
//         // } catch (ex) {
//         //   this.setState({ isProcessing: false });
//         //   if (ex.response && ex.response.status === 400) {
//         //     this.setState({ apiError: ex.response.data.message });
//         //   }
//         // }
//     };

//     render() {
//         const { data, errors, isProcessing } = this.state;
//         return (

//         );
//     }
// }

// export default PurchaseForm;
