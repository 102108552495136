import "bootstrap/dist/css/bootstrap.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { useState } from "react";

function ProductSlider({ mobile }) {
    const [data, setData] = useState([
        {
            id: "1",
            name: "PREGNANT WITH YOU",
            description: "“A memoir style journal for pregnant women to capture their pregnancy journey.”",
            image: `url("/products/pregnant.jpg")`
        },
        {
            id: "2",
            name: "THE BLACK BOARD",
            description: "“An everyday journal for children and adolescents between the ages of 8-15 years.”",
            image: `url("/products/black-board.jpg")`
        },
        {
            id: "3",
            name: "THE RED BOOK",
            description: "“A self-reflective journal for young adults and adults between the ages of 16-65+”",
            image: `url("/products/red-book.jpg")`
        },
        {
            id: "4",
            name: "A JOT A DAY",
            description: "“A limited edition journal for children and adolescents between the ages of 8-15 years.”",
            image: `url("/products/jot-a-day.jpg")`
        },
        {
            id: "5",
            name: "THE JOURNEY",
            description: "“A life enhancing journal for individuals with cancer and other prolonged diseases”",
            image: `url("/products/journey.jpg")`
        }
    ]);

    return (
        <Swiper
            loop={true}
            className="swiper-container"
            modules={[Autoplay, Pagination]}
            pagination={{ clickable: true }}
            autoplay={{ delay: 5500 }}
            speed={1000}
            spaceBetween={0}
            slidesPerView={mobile ? 1 : 3}
        >
            {data.map((item, index) => (
                <SwiperSlide key={item.id}>
                    {({ isNext }) => (
                        <div id={`${index}`} style={{ marginBottom: 20 }} className="d-flex align-items-center flex-column ">
                            <h2 className="product-heading" style={isNext ? { color: "black" } : { color: "#ffc328" }}>
                                {item.name}
                            </h2>
                            <div className="product-image" style={{ backgroundImage: item.image }} />
                            <p className="product-description">{item.description}</p>
                        </div>
                    )}
                </SwiperSlide>
            ))}
        </Swiper>
    );
}

export default ProductSlider;
