import React, { useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import PurchaseForm from "../PurchaseForm";
import { SwiperNavButtons } from "../SwiperNavButtons";

function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);
    useEffect(() => {
        const handleResize = () => {
            setSize([window.innerHeight, window.innerWidth]);
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return size;
}

function Products() {
    const [height, width] = useWindowSize();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [selected, setSelected] = useState("");

    const [data, setData] = useState([
        {
            id: "1",
            name: "PREGNANT WITH YOU",
            description: "“A memoir style journal for pregnant women to capture their pregnancy journey.”",
            image: width > 768 ? `url("/products/pregnant.jpg")` : `url("/products/mobile/pregnant.jpg")`
        },
        {
            id: "2",
            name: "THE BLACK BOARD",
            description: "“An everyday journal for children and adolescents between the ages of 8-15 years.”",
            image: width > 768 ? `url("/products/black-board.jpg")` : `url("/products/mobile/black-board.jpg")`
        },
        {
            id: "3",
            name: "THE RED BOOK",
            description: "“A self-reflective journal for young adults and adults between the ages of 16-65+”",
            image: width > 768 ? `url("/products/red-book.jpg")` : `url("/products/mobile/red-book.jpg")`
        },
        {
            id: "4",
            name: "A JOT A DAY",
            description: "“A limited edition journal for children and adolescents between the ages of 8-15 years.”",
            image: width > 768 ? `url("/products/jot-a-day.jpg")` : `url("/products/mobile/jot-a-day.jpg")`
        },
        {
            id: "5",
            name: "THE JOURNEY",
            description: "“A life enhancing journal for individuals with cancer and other prolonged diseases”",
            image: width > 768 ? `url("/products/journey.jpg")` : `url("/products/mobile/journey.jpg")`
        }
    ]);
    return (
        <>
            <Swiper
                spaceBetween={30}
                effect={"fade"}
                navigation={true}
                pagination={{
                    clickable: true
                }}
                modules={[EffectFade, Navigation]}
                className="mySwiper"
            >
                {data.map((item, index) => (
                    <SwiperSlide key={item.id}>
                        {({ isActive }) => (
                            <div style={{ backgroundImage: item.image }} className="product-container">
                                <div id={`${index}`} className="d-flex align-items-center justify-content-center h100 flex-column">
                                    <h2 className="product-title" style={isActive ? { color: "white" } : { color: "transparent", webkitTextStroke: "3px #5d5d5d" }}>
                                        {item.name}
                                    </h2>
                                    {isActive ? <p className="product-details">{item.description}</p> : null}
                                    <Button
                                        onClick={() => {
                                            setSelected(item);
                                            handleShow();
                                        }}
                                        className="buy-button"
                                    >
                                        Buy Now
                                    </Button>
                                </div>
                                {/* <SwiperNavButtons /> */}
                            </div>
                        )}
                    </SwiperSlide>
                ))}
            </Swiper>
            <Modal className="product-modal" show={show} onHide={handleClose}>
                <Modal.Body>
                    <div className="product-layout">
                        <PurchaseForm onHide={handleClose} />
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Products;
