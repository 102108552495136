import React from "react";
import { Col, Row } from "react-bootstrap";
import { useForm, ValidationError } from "@formspree/react";
import Tada from "react-reveal/Tada";

function ContactForm({ mobile }) {
    const [state, handleSubmit] = useForm("mqknobjj");
    return (
        <form onSubmit={handleSubmit}>
            {!mobile ? (
                <Row className="w-100" style={{ textAlign: "start" }}>
                    <Col className="p-5" md={6}>
                        <Tada>
                            <h3 className="feedback-heading">Let's talk...</h3>
                        </Tada>
                        <p style={{ fontSize: 20, color: "white", marginTop: 30, padding: "0px 10px" }}>
                            Let's collaborate and change lives for the Better. Fill the form and we will get back to you, swiftly.
                        </p>
                        {!state.succeeded ? (
                            <button className="btn writeButton center" type="submit" disabled={state.submitting}>
                                SUBMIT
                            </button>
                        ) : null}
                    </Col>
                    <Col className="p-4" style={{ paddingRight: 90 }} md={6}>
                        {!state.succeeded ? (
                            <>
                                <div className="mb-3 formStart">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>

                                    <input required id="name" className="form-control transparentInput" name="name" label="Name" placeholder="Name" />
                                    <ValidationError prefix="Name" field="name" errors={state.errors} />
                                </div>

                                <div className="mb-3 formStart">
                                    <label htmlFor="email" className="form-label">
                                        Email
                                    </label>

                                    <input required id="email" type="email" className="form-control transparentInput" name="email" label="Email" placeholder="Email" />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </div>
                                <div className="mb-3 formStart">
                                    <label htmlFor="phone" className="form-label">
                                        Phone
                                    </label>

                                    <input
                                        type="tel"
                                        maxLength={10}
                                        title="Ten digits"
                                        pattern="[1-9]{1}[0-9]{9}"
                                        required
                                        id="phone"
                                        className="form-control transparentInput"
                                        name="phone"
                                        label="Phone"
                                        placeholder="Phone"
                                    />
                                    <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                                </div>
                                <div className="mb-3 formStart">
                                    <label htmlFor="message" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        style={{ backgroundColor: "#595959", border: 0, height: 250, color: "white" }}
                                        className="form-control "
                                        name={"message"}
                                        id={"message"}
                                        label="message"
                                        placeholder={"Message"}
                                    />

                                    <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                                </div>
                            </>
                        ) : (
                            <h3 className="feedback-heading">Thank you, we'll get back to you.</h3>
                        )}
                    </Col>
                </Row>
            ) : (
                <Row className="w-100" style={{ textAlign: "start", margin: 0 }}>
                    <Col>
                        <Tada>
                            <h3 className="feedback-heading">Let's Talk...</h3>
                        </Tada>
                        <p style={{ fontSize: 13, color: "white", marginTop: 30 }}>Let's collaborate and change lives for the Better. Fill the form and we will get back to you, swiftly.</p>
                        {!state.succeeded ? (
                            <>
                                <div className="mb-3 formStart">
                                    <label htmlFor="name" className="form-label">
                                        Name
                                    </label>

                                    <input required id="name" className="form-control transparentInput" name="name" label="Name" placeholder="Name" />
                                    <ValidationError prefix="Name" field="name" errors={state.errors} />
                                </div>

                                <div className="mb-3 formStart">
                                    <label htmlFor="email" className="form-label">
                                        Email
                                    </label>

                                    <input required id="email" type="email" className="form-control transparentInput" name="email" label="Email" placeholder="Email" />
                                    <ValidationError prefix="Email" field="email" errors={state.errors} />
                                </div>
                                <div className="mb-3 formStart">
                                    <label htmlFor="phone" className="form-label">
                                        Phone
                                    </label>

                                    <input
                                        type="tel"
                                        maxLength={10}
                                        title="Ten digits"
                                        pattern="[1-9]{1}[0-9]{9}"
                                        required
                                        id="phone"
                                        className="form-control transparentInput"
                                        name="phone"
                                        label="Phone"
                                        placeholder="Phone"
                                    />
                                    <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                                </div>
                                <div className="mb-3 formStart">
                                    <label htmlFor="message" className="form-label">
                                        Message
                                    </label>
                                    <textarea
                                        style={{ backgroundColor: "#595959", border: 0, height: 250, color: "white" }}
                                        className="form-control "
                                        name={"message"}
                                        id={"message"}
                                        label="message"
                                        placeholder={"Message"}
                                    />
                                    <ValidationError prefix="Phone" field="phone" errors={state.errors} />
                                </div>
                                <button className="btn writeButton center" type="submit" disabled={state.submitting}>
                                    SUBMIT
                                </button>
                            </>
                        ) : (
                            <h3 className="feedback-heading">Thank you, we'll get back to you.</h3>
                        )}
                    </Col>
                </Row>
            )}
        </form>
    );
}

export default ContactForm;
