import Lottie from "lottie-react";
import animationData from "../../lottie/waves-mobile.json";
import counterAnimation from "../../lottie/counter.json";
import { Col, Row } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.css";
import { useState } from "react";
import ContactForm from "../ContactForm";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCards } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Collapse from "react-bootstrap/Collapse";
import ProductSlider from "../ProductSlider";
import TestimonialsMobile from "../TestimonialsMobile";
import ClienteleMobile from "../ClienteleMobile";
import { Zoom, Flip } from "react-reveal";
import Tada from "react-reveal/Tada";

function Mobile() {
    const [stopped, setStopped] = useState(false);
    const [navbar, setNavbar] = useState(false);
    const [open, setOpen] = useState(false);
    const [startCounter, setStartCounter] = useState(false);

    const changeNavbar = () => {
        if (window.scrollY >= 80) {
            setNavbar(true);
        } else {
            setNavbar(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setStartCounter(true);
            } else {
                setStartCounter(false);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        window.addEventListener("scroll", changeNavbar);
    }, []);

    return (
        <>
            <div className="home" id="home">
                <div className="lottie-bg">
                    {!stopped ? (
                        <Lottie
                            rendererSettings={{
                                preserveAspectRatio: "xMidYMid slice"
                            }}
                            animationData={animationData}
                            loop={false}
                            autoPlay
                            style={{ height: "100vh", width: "100vw", flex: 1 }}
                            onComplete={() => setStopped(true)}
                        />
                    ) : null}
                    {stopped ? (
                        <div className="position-relative p-3 p-md-5 overflow-hidden" style={{ zIndex: 100 }}>
                            <img src="/icons/main-logo.gif" alt="logo" className="main-logo" />
                            <Flip left>
                                <h3 className="main-heading">A Symbol of Solidarity Against Stigma on Mental Health</h3>
                            </Flip>
                        </div>
                    ) : null}
                </div>
            </div>
            {startCounter ? (
                <div className="count-container">
                    <Lottie style={{ width: "100%", height: 450, marginTop: -200 }} autoplay animationData={counterAnimation} loop={false} />
                    <h2 className="counter-text">of our users are students holding a deliberate and thoughtful conversation with themselves in a completely unaltered voice through our journals.</h2>
                </div>
            ) : null}

            <Zoom left>
                <div className="health-day">
                    <h2 className="mental-health-heading">WORLD MENTAL HEALTH DAY 2022</h2>
                    <p className="health-day-subheading">Our Proud Winners</p>
                    <Row className="health-image-container">
                        <Col>
                            <Swiper pagination modules={[Autoplay, EffectCards]} effect={"cards"} loop autoplay={{ delay: 1000 }} speed={1000}>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo1.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo2.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo3.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo4.jpeg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo5.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo6.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo7.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo8.jpg" />
                                    </div>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className="photo-card">
                                        <img alt="user" className="health-day-image" src="/workshop/photo9.jpg" />
                                    </div>
                                </SwiperSlide>
                            </Swiper>
                            <p className="mental-health-content">
                                In Honour of World Mental Health Day, 2022, The Better Life Projects organised 'BACK TO BASICS' - A Space for students to creatively express themselves and be
                                recognised for it.
                            </p>
                        </Col>
                    </Row>
                </div>
            </Zoom>

            <Zoom left>
                <div style={{ marginTop: "-5rem" }}>
                    <svg style={{ marginBottom: -2 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="#981E31"
                            fill-opacity="1"
                            d="M0,192L21.8,186.7C43.6,181,87,171,131,154.7C174.5,139,218,117,262,138.7C305.5,160,349,224,393,250.7C436.4,277,480,267,524,234.7C567.3,203,611,149,655,144C698.2,139,742,181,785,176C829.1,171,873,117,916,117.3C960,117,1004,171,1047,181.3C1090.9,192,1135,160,1178,144C1221.8,128,1265,128,1309,133.3C1352.7,139,1396,149,1418,154.7L1440,160L1440,320L1418.2,320C1396.4,320,1353,320,1309,320C1265.5,320,1222,320,1178,320C1134.5,320,1091,320,1047,320C1003.6,320,960,320,916,320C872.7,320,829,320,785,320C741.8,320,698,320,655,320C610.9,320,567,320,524,320C480,320,436,320,393,320C349.1,320,305,320,262,320C218.2,320,175,320,131,320C87.3,320,44,320,22,320L0,320Z"
                        ></path>
                    </svg>
                    <div className="story">
                        <h2 className="story-heading">Our Story</h2>

                        <p className="story-content">
                            Our story began when we realised everybody likes talking about the importance of mental health and the need to create awareness, but without any direction or consequence.
                            Mental health is more than just creating awareness. Awareness should motivate action by creating opportunities for people to benefit from. Our story began when we realised
                            there was a disparity in understanding that health consists of both physical and mental well-being. Our goal is not just to propagate but deliver viable means to practice
                            sound mental health. We are reintroducing the lost art of journaling to every individual. To explore their BIG feelings in a safe and non-judgemental environment. Our
                            journals are created with the belief that positive mental health is everyone’s birth right, and it no longer is optional or can be ignored due to long-held stigma against
                            it.
                        </p>
                    </div>
                </div>
            </Zoom>

            <Zoom right>
                <div style={{ marginTop: -63, zIndex: 8 }}>
                    <svg style={{ marginBottom: -2 }} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                        <path
                            fill="#2E1E4D"
                            fill-opacity="1"
                            d="M0,160L24,144C48,128,96,96,144,74.7C192,53,240,43,288,69.3C336,96,384,160,432,176C480,192,528,160,576,165.3C624,171,672,213,720,202.7C768,192,816,128,864,117.3C912,107,960,149,1008,160C1056,171,1104,149,1152,122.7C1200,96,1248,64,1296,74.7C1344,85,1392,139,1416,165.3L1440,192L1440,320L1416,320C1392,320,1344,320,1296,320C1248,320,1200,320,1152,320C1104,320,1056,320,1008,320C960,320,912,320,864,320C816,320,768,320,720,320C672,320,624,320,576,320C528,320,480,320,432,320C384,320,336,320,288,320C240,320,192,320,144,320C96,320,48,320,24,320L0,320Z"
                        ></path>
                    </svg>
                    <div className="about" id="about">
                        <h2 className="about-heading">About the author</h2>

                        <Row className="w-100" style={{ margin: 0 }}>
                            <Col md={12} style={{ padding: "0px 28px" }}>
                                <Row className="align-items-center">
                                    <Col>
                                        <img alt="user" className="author-image" src="/about/author.png" />
                                    </Col>
                                    <Col>
                                        <h6 className="designation">Founder: The Better Life Projects</h6>
                                        <h6 className="designation">Member of British Psychological Society</h6>
                                        <h6 className="designation">Post Graduate Criminology and Criminal Justice</h6>
                                        <div className="d-flex align-items-center justify-content-start">
                                            <img alt="ted ex logo" className="ted-ex" src="/about/ted.png" />
                                            <h6 className="designation">Speaker</h6>
                                        </div>
                                    </Col>
                                </Row>
                                <p className="author-details">
                                    I am not going to bore you by talking about myself in third person. I have always enjoyed direct and personal conversations, and this is going to be no different.
                                </p>

                                <p className="author-details">
                                    I am Sapna Prajapati, the founder of The Better Life Projects. I create content that encourages positive self-talk, prioritisation of emotions, and coming to terms
                                    with fears and overcoming them through journaling.
                                </p>

                                <p className="author-details">
                                    Apart from this fancy stuff, I hold a Post Graduate degree in Criminology & Criminal Justice, from the University of Edinburgh and hold a Psychology Membership from
                                    the British Psychological Society. In a nutshell, I am an aspiring psychological researcher and a wanderlust.
                                </p>
                                <Collapse in={open}>
                                    <div id="read-more">
                                        <p className="author-details">
                                            Now after reading these imposing things about me if you are wondering, what my story is? I want to prioritise mental health by creating a space free of
                                            judgements. My hope is that the importance of good and positive mental health is no longer taken for granted or worse concealed just because some people
                                            disapprove of it without understanding its true meaning.
                                        </p>

                                        <p className="quote">Mental Health is not a propaganda. IT IS A PRIORITY.</p>
                                    </div>
                                </Collapse>
                                <Button className="read-more-button" onClick={() => setOpen(!open)} aria-controls="read-more" aria-expanded={open}>
                                    READ MORE
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </div>
            </Zoom>

            <Zoom left>
                <div className="creation" id="product">
                    <Tada>
                        <h2 className="creation-heading">Our Creations</h2>
                    </Tada>

                    <div style={{ marginTop: 20 }}>
                        <ProductSlider mobile={true} />
                    </div>
                </div>
            </Zoom>

            <Zoom right>
                <div className="give-back">
                    <h2 className="give-back-heading">WE GIVE BACK</h2>

                    <Row className="d-flex px-5" style={{ margin: "40px 0 0 0" }}>
                        <Col md={12} style={{ textAlign: "start" }}>
                            <img alt="user" className="give-back-image" src="/images/we-give.png" />
                            <div className="give-back-content">
                                <p>When the idea of creating journals was conceived, what was even more amazing was that we had stumbled upon an opportunity to give back to the society.</p>
                                <p>
                                    We at The Better Life Projects are working with organisations that help children, adults, and seniors who are differently abled; are HIV or cancer inflicted; and
                                    Trusts that home orphans. We want to be a part of their journey by being able to provide for their food, shelter, clothing, education, and medical expenditures.
                                    Every single time a journal is bought, a part of the proceeds goes to an NGO. When you decide to purchase a journal from us you are not just helping yourself but
                                    also these individuals who are in dire need of basic facilities. In the very near future, we aspire to donate to animal shelters given the fact that animal abuse
                                    and neglect is on the rise, and that they deserve a better life.
                                </p>
                                <p>The details of the NGO that you would be contributing to is mentioned inside the journal you choose to purchase.</p>
                                <p>Thank you for spinning the wheel of giving.</p>
                            </div>
                        </Col>
                    </Row>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <hr style={{ color: "white", width: "50%", borderWidth: 3 }} />
                    </div>
                </div>
            </Zoom>

            <TestimonialsMobile />

            <ClienteleMobile />

            <div id="contact" className="feedback flex-column d-flex align-items-center justify-content-center">
                <ContactForm mobile={true} />
                <Tada>
                    <h2 style={{ color: "white", marginTop: "3rem", width: "100%" }} className="dont-let-text">
                        Don't let your story end...
                    </h2>
                </Tada>
            </div>

            <div className="footer d-flex flex-row justify-content-around align-items-center">
                <div>
                    <h3 className="footer-address-heading">ADDRESS</h3>
                    <p className="footer-address">The Better Life Projects JP Nagar, Bangalore - 560078</p>
                    <p className="footer-address">Mobile: +91 7975311483</p>
                    <p className="footer-address">Email : info@thebetterlifeprojects.com</p>
                </div>
                <div>
                    <img src="/icons/logo.png" alt="logo" className="contact-logo" />
                    <div style={{ marginTop: 60, display: "flex" }}>
                        <img src="/icons/instagram.png" alt="instragram logo" className="socials-logo" />
                        <img src="/icons/facebook.png" alt="facebook logo" className="socials-logo" />
                        <img src="/icons/twitter.png" alt="twitter logo" className="socials-logo" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Mobile;
