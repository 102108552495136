// import { HashRouter } from "react-router-dom";
import "./App.css";
import Routes from "./routes/Routes";

function App() {
    return (
        // <HashRouter>
        <Routes />
        // </HashRouter>
    );
}

export default App;
